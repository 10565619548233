'use client'
import type { ReactNode } from 'react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
import { mq } from '@/styles'
import { Figure } from '@/components'
import { usePathname } from 'next/navigation'

const DynamicModalForgotPassword = dynamic(() => import('@/components/modals/ModalForgotPassword').then(mod => mod.ModalForgotPassword), { ssr: false })

const mediaCat = {
  type: 'image',
  alt: 'Background cat image',
  default: {
    src: '/images/auth-login-cat.png',
    size: {
      height: 1800,
      width: 1440
    }
  }
} as const

const mediaDog = {
  type: 'image',
  alt: 'Background dog image',
  default: {
    src: '/images/auth-signup-dog.png',
    size: {
      height: 1800,
      width: 810
    }
  }
} as const

const mediaLogoVirbac = {
  type: 'svg',
  src: '/images/svg/logo-virbac-text.svg',
  alt: 'Close modal icon',
  size: { width: 337, height: 74 }
} as const

export default function AuthLayout({ children }: { children: ReactNode }) {
  const pathname = usePathname()

  return (
    <>
      <Div $isSignUpPage={pathname === '/signup'} >
        <Header>
          <Figure media={pathname === '/signup' ? mediaDog : mediaCat} sizes='50vw' lazy={false} />
          <Figure media={mediaLogoVirbac} lazy={false} className='logo'/>
        </Header>
        <Main>
          {children}
        </Main>
      </Div>
      <DynamicModalForgotPassword />
    </>
  )
}

const Div = styled.div<{ $isSignUpPage: boolean }>`
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;

  header {
    ${mq.greaterThan('tablet')} {
      width: ${({ $isSignUpPage }) => $isSignUpPage ? '30vw' : '50vw'};
      order: ${({ $isSignUpPage }) => $isSignUpPage ? 1 : 0}
    }
  }

  .logo {
    bottom: ${({ $isSignUpPage }) => $isSignUpPage ? 'auto' : '30px'};
    top: ${({ $isSignUpPage }) => $isSignUpPage ? '30px' : 'auto'};
    left: ${({ $isSignUpPage }) => $isSignUpPage ? 'auto' : '30px'};
    right: ${({ $isSignUpPage }) => $isSignUpPage ? '30px' : 'auto'};
  }

  main {
    ${mq.greaterThan('tablet')} {
      width: ${({ $isSignUpPage }) => $isSignUpPage ? '70vw' : '50vw'};
    }
  }
`

const Header = styled.header`
  display: none;

  ${mq.greaterThan('tablet')} {
    display: block;
    /* width: 50vw; */
  }

  figure {
    ${mq.greaterThan('tablet')} {
      height: 100vh;
      width: 100%;
    }
  }

  img {
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  .logo {
    height: 73px;
    position: absolute;
    width: 337px;
  }
`

const Main = styled.main`
  align-items: center;
  background-color: var(--color-background_login);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  overflow: auto;
  padding: 0 10px;
  width: 100vw;

  ${mq.greaterThan('tablet')} {
    padding: 0 20px;
    /* width: 50vw; */
  }

  hgroup {
    margin: 100px auto 50px;

    h1, p {
      text-align: left;
    }

    a {
      color: var(--color-blue);
      font-weight: 500;
    }
  }

  h1 {
    margin-bottom: 10px;
  }

  form {
    margin: 0 auto 100px;
    max-width: 400px;
    width: 100%;
  }

  hgroup {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h1, p {
      max-width: 400px;
      text-align: left;
      width: 100%;
    }
  }
`
